/**
 * Generate a email address which is harder for SPAM-bots to read
 *
 * @param lhs string Before the @
 * @param rhs string After the @
 * @param area string Object selector for Jquery
 */
function genMailLink(lhs, rhs, area) {

    let code = "<a href=\"mailto";
    code += ":" + lhs + "@";
    code += rhs + "\">" + lhs + "@" + rhs + "<\/a>";

    $(area).html(code);
}


/**
 * Load a part of a site into a container
 *
 * @param url
 * @param container
 * @param callback
 * @returns {boolean}
 */
function loadPartial(url, container, callback){
    console.log('loading ' + url);
    $(container).load(url, function (response, status, xhr) {
        if (status == "error") {
            $(container).html('<div class="alert alert-danger">Could not load</div>');
        }

        if (callback) {
            callback.success();
        }
    });

    return true;
}